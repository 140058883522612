<template>
  <div>
    <div class="form-col flex">
      <label class="mr-2">attachments</label>
      <a
        v-if="overflow"
        href="#"
        class="font-frank font-normal text-2sm text-active-500 underline tracking-wider cursor-pointer hover:text-active-600"
        @click.prevent="handleOpen"
      >
        see all ({{attachments.length}})
      </a>
    </div>
    <div v-if="readonly && attachments.length === 0">-</div>
    <Attachments
      :data-provider="dataProvider"
      :resource="resource"
      :service="service"
      :max-items="maxItems"
      :accept="accept"
      :items="items"
      :with-images="withImages"
      :readonly="readonly"
      :buttonLabel="buttonLabel"
      :buttonIcon="buttonIcon"
      @overflow="handleOverflow"
    />
    <div v-if="!readonly" class="text-3xs text-black">
      <span v-if="formats">{{formats}}</span>
      <span v-if="formats && maxItems"> / </span>
      <span v-if="maxItems">max {{maxItems}} {{fileType}}</span>
    </div>
  </div>
</template>

<script>
import Attachments from '@/components/ui/Attachments';
import AttachmentMixin from '@/mixins/AttachmentMixin';
import {acceptToFormats} from '@/utils/files';
import DataProvider from "@/api/DataProvider";

export default {
  mixins: [
    AttachmentMixin,
  ],

  components: {
    Attachments,
  },

  props: {
    /**
     * Data provider to fetch upload/download URLs. `$attachmentsDataProvider` is used default.
     */
    dataProvider: {
      type: DataProvider,
      required: false,
    },

    /**
     * Name of resource used by the data provider. May be needed when custom data provider is used.
     */
    resource: {
      type: String,
      default: 'attachments',
    },

    /**
     * Owner service code (e.g. `'MSGS'`, `'SREQ'`, etc.)
     */
    service: {
      type: String,
      required: true,
    },

    /**
     * Maximum number of attachments allowed
     */
    maxItems: {
      type: Number,
      default: 0,
    },

    /**
     * Initial array of attachments
     */
    items: {
      type: Array,
      required: false,
    },

    /**
     * Array of strings representing acceptable mime types
     */
    accept: {
      type: Array,
      required: false,
    },

    /**
     * Should image previews be shown instead of filenames
     */
    withImages: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

     /**
     * Name of file type to customize max elements description label
     */
    fileType: {
      type: String,
      default: 'images',
    },

     /**
     * Button label name.
     */
    buttonLabel: {
      type: String,
      default: 'add images',
    },

    /**
     * Button icon name.
     */
    buttonIcon: {
      type: String,
      default: 'camera2',
    },
  },

  data() {
    return {
      overflow: false,
    };
  },

  computed: {
    formats() {
      return acceptToFormats(this.accept);
    },
  },

  methods: {
    handleOverflow(value) {
      this.overflow = value;
    },

    handleOpen() {
      this.$router.push({ name: 'atts.gallery' });
    },
  },
};
</script>
